<template>
  <div class="readme-article">
    <h1 id="学生课费">
      二、学生课费
    </h1>
    <h2 id="停课预警">1.停课预警</h2>
    <p>显示停课预警得学员列表，支持条件查询</p>
    <p>
      <img src="@/assets/img/student/2-1.png">
    </p>
    <h2 id="课费预警">2.课费预警</h2>
    <p>显示还有剩余课时，n天未上课得学员列表，支持条件查询</p>
    <p>
      <img src="@/assets/img/student/2-2.png">
    </p>
    <h2 id="学生报班查询">3.学生报班查询</h2>
    <p>查看学生报班记录，展示学生报班名称，课时记录等。</p>
    <p>
      <img src="@/assets/img/student/2-3.png">
    </p>
    <h2 id="学生订课查询">4.学生订课查询</h2>
    <p>查看学生订课记录，展示学生订课名称，课费课时等信息。</p>
      <p>
      <img src="@/assets/img/student/2-4.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student2-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>